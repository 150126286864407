var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('Header',{attrs:{"active":3}}),_c('div',{staticClass:"banner-box"},[_c('el-image',{staticClass:"banner-box-img",attrs:{"src":"https://ecare-health.oss-cn-beijing.aliyuncs.com/www/tracking/banner.png","lazy":""}}),_c('div',{staticClass:"banner-box-text-box"},[_vm._m(0),_c('div',{staticClass:"banner-box-text-box-tips"},[_vm._v(" 针对养老机构中快速追踪病毒接触者 ")]),_c('div',{staticClass:"horizontal-line",style:({ margin: _vm.pxToRem([21, 0, 36, 0]) })}),_c('div',{staticClass:"banner-box-text-box-info"},[_vm._v("       1CARE通过历史轨迹追踪、密切接触定位，积极配合落实防疫措施。对感染源快速位置追踪和及时环境消杀，最大程度阻断疫情扩散传播。 ")])])],1),_c('div',{staticClass:"section1-bg"},[_c('el-image',{staticClass:"section1-bg-img",attrs:{"src":"https://ecare-health.oss-cn-beijing.aliyuncs.com/www/tracking/section1-img2.png","lazy":""}}),_c('div',{staticClass:"section1"},[_vm._m(1),_c('div',{staticClass:"section1-box"},[_vm._m(2),_c('div',{staticClass:"section1-box-right"},[_c('el-image',{attrs:{"src":"https://ecare-health.oss-cn-beijing.aliyuncs.com/www/tracking/section1-img1.png","lazy":""}})],1)]),_c('div',{staticClass:"section1-box m-t-180"},[_c('el-image',{style:({
            width: _vm.pxToRem(227),
            height: _vm.pxToRem(212),
            position: 'absolute',
            top: _vm.pxToRem(-10),
            right: _vm.pxToRem(-150),
          }),attrs:{"src":"https://ecare-health.oss-cn-beijing.aliyuncs.com/www/tracking/section1-img4.png","lazy":""}}),_c('div',{staticClass:"section1-box-right"},[_c('el-image',{staticClass:"w-635 h-615",attrs:{"src":"https://ecare-health.oss-cn-beijing.aliyuncs.com/www/tracking/section1-img3.png","lazy":""}})],1),_vm._m(3)],1),_c('div',{staticClass:"section1-box"},[_vm._m(4),_c('div',{staticClass:"section1-box-right"},[_c('el-image',{staticClass:"w-753 h-696",attrs:{"src":"https://ecare-health.oss-cn-beijing.aliyuncs.com/www/tracking/section1-img5.png","lazy":""}})],1)]),_c('div',{staticClass:"section1-box m-t-36"},[_vm._m(5),_c('div',{staticClass:"section1-box-right"},[_c('el-image',{staticClass:"w-785 h-702",attrs:{"src":"https://ecare-health.oss-cn-beijing.aliyuncs.com/www/tracking/section1-img6.png","lazy":""}})],1)])])],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner-box-text-box-title"},[_c('div',[_vm._v("阻止新型冠状病毒")]),_c('div',[_vm._v("在养老社区中肆意传播")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-box"},[_c('div',{staticClass:"title"},[_vm._v("我们如何克服养老机构中接触者追踪")]),_c('div',{staticClass:"subtitle"},[_vm._v(" How to overcome the contact tracing in the pension agency ")]),_c('div',{staticClass:"horizontal-line m-t-21"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section1-box-left"},[_c('div',{staticClass:"section1-box-left-title"},[_c('span',[_vm._v("历史轨迹追踪")]),_c('img',{attrs:{"src":"https://ecare-health.oss-cn-beijing.aliyuncs.com/www/icon/arrow-right.png","alt":""}})]),_c('div',{staticClass:"horizontal-line m-t-14 m-r-48",staticStyle:{"float":"right"}}),_c('div',{staticClass:"section1-box-left-info"},[_c('div',[_vm._v("     1CARE智能化数字接触者感知解决方案，专门用于阻止致命的冠状病毒在高风险、高密度地区的传播，例如养老院和学校； ")]),_c('div',[_vm._v("     使用1CARE接触者感知模型，工作人员可以快速识别所有因接触感染者或接触受感染者可能污染过的物品而面临风险的人群； ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section1-box-left w-486 m-t-148",staticStyle:{"margin-right":"0"}},[_c('div',{staticClass:"section1-box-left-title",staticStyle:{"justify-content":"flex-start"}},[_c('img',{staticClass:"m-r-25",staticStyle:{"margin-left":"0"},attrs:{"src":"https://ecare-health.oss-cn-beijing.aliyuncs.com/www/icon/arrow-left.png","alt":""}}),_c('span',[_vm._v("密切接触定位")])]),_c('div',{staticClass:"horizontal-line m-t-14 m-l-50"}),_c('div',{staticClass:"section1-box-left-info"},[_c('div',[_vm._v("     使用1CARE的接触者感知模型，可以在几秒钟内识别出所有接触过疑似携带者的居民、工作人员和访客； ")]),_c('div',[_vm._v("     系统按密接或次密接对接触者进行不同级别分类，使工作人员能够了解暴露于高风险的接触者，防止病毒在社区内形成传播； ")]),_c('div',[_vm._v("     通过检测和隔离阻断构成严重威胁的病毒，有效防止病毒的社区间传播； ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section1-box-left w-394 m-r-50"},[_c('div',{staticClass:"section1-box-left-title",staticStyle:{"justify-content":"flex-start"}},[_c('span',[_vm._v("位置追踪")]),_c('img',{attrs:{"src":"https://ecare-health.oss-cn-beijing.aliyuncs.com/www/icon/arrow-right.png","alt":""}})]),_c('div',{staticClass:"horizontal-line m-t-14"}),_c('div',{staticClass:"section1-box-left-info"},[_c('div',[_vm._v("     位置追踪不是要暴露使用人的具体地点或位置，而是为了区域追踪病毒的传播路径；病毒感染者在离开某个区域后，病毒仍会在空气和物体表面存活，1CARE位置感知技术，通过提供感染者活动轨迹信息，防止交叉感染传播； ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section1-box-left w-394 m-r-20"},[_c('div',{staticClass:"section1-box-left-title",staticStyle:{"justify-content":"flex-start"}},[_c('span',[_vm._v("环境消杀")]),_c('img',{attrs:{"src":"https://ecare-health.oss-cn-beijing.aliyuncs.com/www/icon/arrow-right.png","alt":""}})]),_c('div',{staticClass:"horizontal-line m-t-14"}),_c('div',{staticClass:"section1-box-left-info"},[_c('div',[_vm._v("     在易受新冠病毒感染影响的养老机构中，环境消杀是至关重要的预防感染有效措施，1CARE可以提供精准的消杀路径，避免养老机构全面停业进行消毒工作，减少不必要的大量工作量和人员流动； ")])])])
}]

export { render, staticRenderFns }